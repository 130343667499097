import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import Headline from "../../components/headline";
import DynamicContent from "../../components/dynamicContent";
import { Grid, Typography, useScrollTrigger, Slide } from "@material-ui/core";
import { ContactSupportOutlined } from "@material-ui/icons";
import ReactMarkdown from "react-markdown";
// import useScrollTrigger from "@material-ui/core/useScrollTrigger";
const parse = require("html-react-parser");

const PrivacyPage = () => {
  const data = useStaticQuery(query);
  const { Heading, PrivacyText } = data.strapiDatenschutz;

  return (
    <Layout>
      <Typography variant="h2">{Heading}</Typography>

      {PrivacyText ? (
        <ReactMarkdown
          source={PrivacyText}
          escapeHtml={false}
          className={"privacy-content"}
        />
      ) : null}
    </Layout>
  );
};

const query = graphql`
  query {
    strapiDatenschutz {
      Heading
      PrivacyText
      id
    }
  }
`;

export default PrivacyPage;
